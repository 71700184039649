

import {
  TOGGLE_DARK_MODE,
  SET_LIVESTREAM_URL,
  SET_PLAY_STREAM,
  SET_ALLOWED_CONFIG,
  ENABLE_COMMISSION,
} from './commonActionTypes';

export const toggleDarkMode = (value: string) => {
  return {
    type: TOGGLE_DARK_MODE,
    payload: value,
  };
};

export const setPlayStream = (value: boolean) => {
  return {
    type: SET_PLAY_STREAM,
    payload: value,
  };
};

export const setLivestreamUrl = (url: string) => {
  return {
    type: SET_LIVESTREAM_URL,
    payload: url,
  };
};

export const setAllowedConfig = (allowedConfig: number) => {
  return {
    type: SET_ALLOWED_CONFIG,
    payload: allowedConfig,
  };
};

export const enableCommission = (commission: boolean) => {
  return {
    type: ENABLE_COMMISSION,
    payload: commission,
  };
};
