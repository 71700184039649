import {
  RESET_STATE,
  SET_EVENT_TYPE,
  FETCH_COMPETITIONS_BY_EVENT_TYPE_SUCCESS,
  SET_COMPETITION,
  FETCH_EVENT_BY_COMPETITION_SUCCESS,
  SET_EXCH_EVENT,
  SET_LOADING,
  RESET_EVENTS,
  UPDATE_SECONDARY_MARKETS,
  DISABLE_EVENT_DATA,
  UPDATE_SCORECARD,
  UPDATE_SECONDARY_MATCH_ODDS,
  UPDATE_BINARY_MARKETS,
  UPDATE_PREMIUM_MARKETS,
  UPDATE_PREMIUM_MARKETS_WS,
  UPDATE_FANCY_MARKETS,
  UPDATE_BOOKMAKER_MARKETS,
  FETCH_TOTAL_EVENT_LIST,
  FETCH_EVENT_FAILED,
  UPDATE_TOPIC_URLS,
} from './exchangeSportsActionTypes';

import {
  CompetitionEventTypeMap,
  ExchangeSportsState,
} from '../../models/ExchangeSportsState';
import { EXCHANGE_EVENT_TYPES } from '../../constants/ExchangeEventTypes';
import { CompetitionDTO } from '../../models/common/CompetitionDTO';
import { MatchOddsRunnerDTO } from '../../models/common/MatchOddsDTO';
import {
  BookmakerDTO,
  BookmakerRunnerDTO,
} from '../../models/common/BookmakerDTO';
import { FancyMarketDTO } from '../../models/common/FancyMarketDTO';
import { ExchangePriceDTO } from '../../models/common/ExchangePriceDTO';
import { capitalize } from '../../util/stringUtil';
// import { capitalize } from '../../util/stringUtil';

type Action = {
  type: string;
  payload: any;
};

const initialState: ExchangeSportsState = {
  eventTypes: EXCHANGE_EVENT_TYPES,
  selectedEventType: { id: '', name: '', slug: '' },
  fetchingEvents: false,
  competitions: {},
  selectedCompetition: { id: '', name: '', slug: '' },
  events: {},
  selectedEvent: { id: '', name: '', slug: '' },
  secondaryMatchOddsMap: {},
  secondaryMarketsMap: {},
  premiumMarketsMap: {},
  scorecard: null,
  binaryMarkets: [],
  totalCountEvents: {},
  topicUrls: {
    matchOddsBaseUrl: '',
    matchOddsTopic: '',
    bookMakerBaseUrl: '',
    bookMakerTopic: '',
    fancyBaseUrl: '',
    fancyTopic: '',
    premiumBaseUrl: '',
    premiumTopic: ''
  }
};

const getMatchOddsSet = (prices: ExchangePriceDTO[]) => {
  let pricesSet: ExchangePriceDTO[] = [];
  if (prices?.length > 0) {
    for (let i = 0; i < 3; i += 1) {
      if (prices[i]) pricesSet.push(prices[i]);
      else pricesSet.push({ price: null, size: null });
    }
  }
  return pricesSet;
};
const getFancyCategory = (runnerName: string) => {
  return 'session-market';
};

const exchangeSportsReducer = (
  state = initialState,
  action: Action
): ExchangeSportsState => {
  switch (action.type) {
    case RESET_STATE: {
      return {
        ...state,
        eventTypes: [],
        selectedEventType: { id: '1', name: 'Football', slug: 'football' },
        competitions: {},
        selectedCompetition: { id: '', name: '', slug: '' },
        events: {},
      };
    }

    case SET_LOADING: {
      return {
        ...state,
        fetchingEvents: action.payload,
      };
    }

    case UPDATE_TOPIC_URLS: {
      return {
        ...state,
        topicUrls: action.payload,
      };
    }

    case SET_EVENT_TYPE: {
      return {
        ...state,
        selectedEventType: action.payload,
      };
    }

    case FETCH_COMPETITIONS_BY_EVENT_TYPE_SUCCESS: {
      let competitions: CompetitionDTO[] = [];
      let allCompetitions: CompetitionEventTypeMap = { ...state.competitions };
      const eId = action.payload.sportId;
      for (let c of action.payload.competitions) {
        const nameSlug = c.competitionName
          .toLocaleLowerCase()
          .replace(/[^a-z0-9]/g, ' ')
          .replace(/ +/g, ' ')
          .trim()
          .split(' ')
          .join('-');
        competitions.push({
          id: c.competitionId,
          name: c.competitionName,
          slug: nameSlug,
          sportId: eId,
        });
      }
      allCompetitions[eId] = competitions;
      return {
        ...state,
        competitions: allCompetitions,
      };
    }

    case SET_COMPETITION: {
      return {
        ...state,
        selectedCompetition: action.payload,
      };
    }
    case FETCH_TOTAL_EVENT_LIST: {
      return {
        ...state,
        totalCountEvents: { ...action.payload },
      };
    }
    case FETCH_EVENT_BY_COMPETITION_SUCCESS: {
      
      // Add events map hierarchy
      const allEvents = { ...state.events };

      for (const item of action.payload) {
        if (!item.sportId) {
          continue;
        }
        let eId: string = item.sportId;
        let cId: string = item.competitionId;
        let eventId: string = item.eventId
          ? item.eventId
          : item.eventData.eventId;

        cId = cId.split(':').join('_');
        eventId = eventId.split(':').join('_');
        if (!allEvents[eId]) {
          allEvents[eId] = {};
        }
        if (!allEvents[eId][cId]) {
          allEvents[eId][cId] = {};
        }

        // Add events data
        if (item.eventData && cId) {
          let eData;
          if (eventId && allEvents[eId][cId][eventId])
            eData = { ...allEvents[eId][cId][eventId] };
          else eData = { ...item.eventData };

          eData.competitionId = eData?.competitionId?.split(':').join('_');
          eData.eventId = eData?.eventId?.split(':').join('_');

          if (eData && eventId !== 'undefined') {
            const eventName = eData.eventName.toLowerCase();
            let homeTeam = eData.homeTeam ? eData.homeTeam : '';
            let awayTeam = eData.awayTeam ? eData.awayTeam : '';
            if (
              (homeTeam === '' &&
                awayTeam === '' &&
                (eventName?.toLowerCase().includes(' v ') ||
                  eventName?.toLowerCase().includes(' vs '))) ||
              eventName?.toLowerCase().includes(' vs. ')
            ) {
              if (eventName.includes(' vs. ')) {
                homeTeam = eventName.split(' vs. ')[0];
                awayTeam = eventName.split(' vs. ')[1];
              } else {
                homeTeam = eventName?.toLowerCase().includes(' v ')
                  ? eventName.split(' v ')[0].trim()
                  : eventName.split(' vs ')[0].trim();
                awayTeam = eventName?.toLowerCase().includes(' v ')
                  ? eventName.split(' v ')[1].trim().split(' - ')[0]
                  : eventName.split(' vs ')[1].trim().split(' - ')[0];
                homeTeam = capitalize(homeTeam);
                awayTeam = capitalize(awayTeam);
              }
            }

            // Set MatchOdds Data.
            const matchOddsData = item.matchOddsData;
            const runners: MatchOddsRunnerDTO[] = [];
            if (matchOddsData) {
              if (
                matchOddsData.runners &&
                matchOddsData.runners.length > 0
              ) {
                let i = 0;
                for (let e of matchOddsData.runners) {
                  if (e) {
                    let runnerName = e.runnerName ? e.runnerName : e.RunnerName;
                    if (runnerName === undefined) {
                      runnerName = '';
                    }

                    if (!runnerName.toLowerCase().includes('draw') && i === 0) {
                      if (runnerName === '') {
                        runnerName = homeTeam;
                      } else if (runnerName !== awayTeam) {
                        homeTeam = runnerName;
                      }
                    }

                    if (!runnerName.toLowerCase().includes('draw') && i !== 0) {
                      if (runnerName === '') {
                        runnerName = awayTeam;
                      } else if (runnerName !== homeTeam) {
                        awayTeam = runnerName;
                      }
                    }

                    i += 1;
                    runners.push({
                      runnerId: e.runnerId,
                      runnerName: runnerName,
                      backPrices: getMatchOddsSet(e.backPrices),
                      layPrices: getMatchOddsSet(e.layPrices),
                      status: e.status,

                      clothNumber: e?.clothNumber,
                      jockeyName: e?.jockeyName,
                      runnerAge: e?.runnerAge,
                      runnerIcon: e?.runnerIcon,
                      stallDraw: e?.stallDraw,
                      trainerName: e?.trainerName,
                    });
                  }
                }
              }
              const bLimits = eData?.matchOdds?.limits;
              eData.matchOdds = {
                marketId: matchOddsData.marketId ? matchOddsData.marketId : '',
                marketName: matchOddsData.marketName
                  ? matchOddsData.marketName
                  : '',
                status: matchOddsData.status ? matchOddsData.status : '',
                runners: runners,
                limits: matchOddsData.limits ? matchOddsData?.limits : bLimits,
              };
            } else {
              let matchOdds = eData.matchOdds;
              if (matchOdds) {
                eData.matchOdds = {
                  marketId: matchOdds?.marketId,
                  marketName: matchOdds?.marketName,
                  status: 'SUSPENDED',
                  runners: matchOdds?.runners,
                  limits: matchOddsData?.limits,
                };
              } else {
                eData.matchOdds = {
                  marketId: '',
                  marketName: '',
                  status: 'SUSPENDED',
                  runners: [],
                  limits: null,
                };
              }
            }

            if (item.raceMarkets?.length > 0) {
              let markets = [];
              for (const rm of item.raceMarkets) {
                const runners: MatchOddsRunnerDTO[] = [];
                if (rm.runners && rm.runners.length > 0) {
                  let i = 0;
                  for (let e of rm.runners) {
                    if (e) {
                      i += 1;
                      runners.push({
                        runnerId: e.runnerId,
                        runnerName: e.runnerName,
                        backPrices: getMatchOddsSet(e.backPrices),
                        layPrices: getMatchOddsSet(e.layPrices),
                        clothNumber: rm?.clothNumber,
                        jockeyName: rm?.jockeyName,
                        runnerAge: rm?.runnerAge,
                        runnerIcon: rm?.runnerIcon,
                        stallDraw: rm?.stallDraw,
                        trainerName: rm?.trainerName,
                        status: e.status,
                      });
                    }
                  }
                }
                markets.push({
                  marketId: rm.marketId,
                  marketName: rm.marketName,
                  marketTime: rm.marketTime,
                  status: rm.status,
                  runners: runners,
                });
              }
              markets.sort((a, b) => {
                const aDesc = a.marketTime;
                const bDesc = b.marketTime;
                if (aDesc > bDesc) return 1;
                else if (aDesc < bDesc) return -1;
                else return 0;
              });
              eData.raceMarkets = markets;
            }

            // Set EventData
            eData.homeTeam = homeTeam;
            eData.awayTeam = awayTeam;
            eData.secondaryMatchOddsMarketIds = action.payload
              .secondaryMatchOddsMarketIds
              ? action.payload.secondaryMatchOddsMarketIds
              : eData.secondaryMatchOddsMarketIds
              ? eData.secondaryMatchOddsMarketIds
              : [];
            eData.eventSlug = eData.eventSlug
              ? eData.eventSlug
              : eData.eventName
              ? eData.eventName
                  .toLowerCase()
                  .replace(/[^a-z0-9]/g, ' ')
                  .replace(/ +/g, ' ')
                  .trim()
                  .split(' ')
                  .join('-')
              : '';

            allEvents[eId][cId][eData.eventId] = eData;
          }
        }

        if (item.onRefresh) {
          let currentSelectedCompetition;
          let currentSelectedEvent;
          let currentSelectedEventType;

          try {
            currentSelectedEventType =
              state.selectedEventType.id === eId
                ? { ...state.selectedEventType }
                : {
                    id: eId,
                    name: EXCHANGE_EVENT_TYPES.filter((e) => e.id === eId)[0]
                      .name,
                    slug: EXCHANGE_EVENT_TYPES.filter((e) => e.id === eId)[0]
                      .slug,
                  };
            currentSelectedCompetition =
              state.selectedCompetition.id === cId
                ? { ...state.selectedCompetition }
                : {
                    id: item.eventData?.competitionId
                      ? item.eventData.competitionId
                      : '',
                    name: item.eventData?.competitionName
                      ? item.eventData.competitionName
                      : '',
                    slug: item.eventData?.competitionName
                      ? item.eventData.competitionName
                          .toLocaleLowerCase()
                          .replace(/[^a-z0-9]/g, ' ')
                          .replace(/ +/g, ' ')
                          .trim()
                          .split(' ')
                          .join('-')
                      : '',
                  };

            currentSelectedEvent =
              state.selectedEvent.id === item.eventData?.eventId
                ? { ...state.selectedEvent }
                : {
                    id: item.eventData?.eventId,
                    slug: item.eventData?.eventName
                      .toLocaleLowerCase()
                      .replace(/[^a-z0-9]/g, ' ')
                      .replace(/ +/g, ' ')
                      .trim()
                      .split(' ')
                      .join('-'),
                    name: item.eventData?.eventName,
                  };
          } catch (_) {
            currentSelectedCompetition = { ...state.selectedCompetition };
            currentSelectedEvent = { ...state.selectedEvent };
            currentSelectedEventType = { ...state.selectedEventType };
          }
          return {
            ...state,
            events: allEvents,
            selectedEventType: currentSelectedEventType,
            selectedCompetition: currentSelectedCompetition,
            selectedEvent: currentSelectedEvent,
          };
        }
      }
      return {
        ...state,
        events: allEvents,
      };
    }

    case UPDATE_SECONDARY_MATCH_ODDS: {
      const eventId: string = action.payload.eventId;
      const marketId: string = action.payload.marketId;

      const allSecMatchOddsMap = { ...state.secondaryMatchOddsMap };

      const matchOddsData = action?.payload?.matchOddsData;

      let runners: MatchOddsRunnerDTO[] = [];
      let runnersData: MatchOddsRunnerDTO[] = allSecMatchOddsMap[
        eventId + '-' + marketId
      ]?.runners?.length
        ? [...allSecMatchOddsMap[eventId + '-' + marketId]?.runners]
        : [];
      if (matchOddsData) {
        if (
          matchOddsData.runners &&
          matchOddsData.runners?.length > 0
        ) {
          let data: any = {};
          for (let e of matchOddsData.runners) {
            if (e) {
              data = runnersData?.find(
                (item) => item?.runnerId === e?.runnerId
              );
              runners.push({
                runnerId: e?.runnerId,
                runnerName: e?.runnerName,
                backPrices: getMatchOddsSet(e.backPrices),
                layPrices: getMatchOddsSet(e.layPrices),
                status: e?.status,
                clothNumber: e?.clothNumber
                  ? e?.clothNumber
                  : data?.clothNumber ?? '',
                jockeyName: e?.jockeyName
                  ? e?.jockeyName
                  : data?.jockeyName ?? '',
                runnerAge: e?.runnerAge ? e?.runnerAge : data?.runnerAge ?? '',
                runnerIcon: e?.runnerIcon
                  ? e?.runnerIcon
                  : data?.runnerIcon ?? '',
                stallDraw: e?.stallDraw ? e?.stallDraw : data?.stallDraw ?? '',
                trainerName: e?.trainerName
                  ? e?.trainerName
                  : data?.trainerName ?? '',
              });
            }
          }
        }

        allSecMatchOddsMap[eventId + '-' + marketId] = {
          marketId: matchOddsData.marketId,
          marketName: matchOddsData.marketName,
          marketTime: matchOddsData.marketTime,
          status: matchOddsData.status,
          runners: runners,
          limits: matchOddsData.limits
            ? matchOddsData.limits
            : allSecMatchOddsMap[eventId + '-' + marketId]?.limits,
        };
      } else {
        let matchOdds = allSecMatchOddsMap[eventId + '-' + marketId];
        if (matchOdds) {
          allSecMatchOddsMap[eventId + '-' + marketId] = {
            marketId: matchOdds.marketId,
            marketName: matchOdds.marketName,
            marketTime: matchOddsData?.marketTime,
            status: 'SUSPENDED',
            runners: matchOdds.runners,
            limits: matchOdds.limits,
          };
        } else {
          allSecMatchOddsMap[eventId + '-' + marketId] = {
            marketId: '',
            marketName: '',
            status: 'SUSPENDED',
            runners: [],
            limits: null,
          };
        }
      }

      const secMatchOddsMap = { ...state.secondaryMatchOddsMap };
      secMatchOddsMap[eventId + '-' + marketId] =
        allSecMatchOddsMap[eventId + '-' + marketId];

      return {
        ...state,
        secondaryMatchOddsMap: secMatchOddsMap,
      };
    }

    case UPDATE_FANCY_MARKETS: {
      const eventId: string = action.payload.eventId;
      const fancyOddsData = action.payload.fancyUpdateData;
      const marketsMap = { ...state.secondaryMarketsMap };

      // Add event data
      if (action.payload.eventId) {
        if (!marketsMap[eventId]) {
          marketsMap[eventId] = {
            ...marketsMap[eventId],
            fancyMarkets: [],
            enableFancy: false,
          };
        }

        // Set Fancy markets data
        let fancyOdds: FancyMarketDTO[] = [];
        if (fancyOddsData && fancyOddsData.length > 0) {
          for (let f of fancyOddsData) {
            fancyOdds.push({
              marketId: f.marketId ? f.marketId : '',
              marketName: f.marketName ? f.marketName : '',
              status: f.status ? f.status : '',
              sort: f.sort ? Number(f.sort) : 0,
              layPrice: f.noValue
                ? f.marketType === 'fancy3' || f.marketType === 'odd-even'
                  ? f.noValue.toFixed(2)
                  : f.noValue
                : null,
              backPrice: f.yesValue
                ? f.marketType === 'fancy3' || f.marketType === 'odd-even'
                  ? f.yesValue.toFixed(2)
                  : f.yesValue
                : null,
              laySize: f.noRate ? f.noRate : null,
              backSize: f.yesRate ? f.yesRate : null,
              category: getFancyCategory(f.category),
            });
          }
        } else if (marketsMap[eventId].fancyMarkets) {
          fancyOdds = marketsMap[eventId].fancyMarkets;
          for (let fMarket of fancyOdds) {
            fMarket.status = 'SUSPENDED';
          }
        }

        fancyOdds.sort((a, b) => {
          const aDesc = a?.marketName?.toLocaleLowerCase();
          const bDesc = b?.marketName?.toLocaleLowerCase();
          if (aDesc > bDesc) return 1;
          else if (aDesc < bDesc) return -1;
          return 0;
        });
        marketsMap[eventId].fancyMarkets = fancyOdds;
        // marketsMap[eventId].enableFancy = action.payload.enableFancy;
      }

      return {
        ...state,
        secondaryMarketsMap: marketsMap,
      };
    }

    case UPDATE_BOOKMAKER_MARKETS: {
      const eventId: string = action.payload.eventId;
      const marketsMap = { ...state.secondaryMarketsMap };
      if (action.payload.eventId) {
        if (!marketsMap[eventId]) {
          marketsMap[eventId] = {
            ...marketsMap[eventId],
            bookmakers: [],
            enableBookmaker: false,
          };
        }

        // Set BookmakerOdds Data.
        const bookMakerOddsData =
          action?.payload?.bookmakerOddsData &&
          Array.isArray(action?.payload?.bookmakerOddsData)
            ? action?.payload?.bookmakerOddsData
            : [action?.payload?.bookmakerOddsData];
        let bookMakerOdds: BookmakerDTO[] = [];
        if (marketsMap[eventId]?.bookmakers?.length)
          bookMakerOdds = marketsMap[eventId]?.bookmakers;
        if (bookMakerOddsData && bookMakerOddsData.length) {
          for (let br of bookMakerOddsData) {
            let bmRunners: BookmakerRunnerDTO[] = [];
            for (let b of br.runners) {
              bmRunners.push({
                runnerId: b.runnerId ? b.runnerId : '',
                runnerName: b.runnerName ? b.runnerName : '',
                backPrice: b.backPrices[0]?.price,
                backSize: b.backPrices[0]?.size,
                layPrice: b.layPrices[0]?.price,
                laySize: b.layPrices[0]?.size,
                status: b.status ? b.status : '',
                sort: b.sort ? Number(b.sort) : 0,
              });
            }
            bmRunners.sort((a, b) => {
              if (a?.sort - b?.sort != 0) {
                return a?.sort - b?.sort;
              }
              const aDesc = a.runnerId;
              const bDesc = b.runnerId;
              if (aDesc > bDesc) return 1;
              else if (aDesc < bDesc) return -1;
              else return 0;
            });
            let index = bookMakerOdds?.length
              ? bookMakerOdds.findIndex((itm) => itm.marketId === br.marketId)
              : -1;
            if (index > -1)
              bookMakerOdds[index] = {
                marketId: br.marketId ? br.marketId : '-1',
                marketName: br.marketName ? br.marketName : 'Bookmaker',
                runners: bmRunners,
                status: br.status ? br.status : 'OPEN',
              };
            else
              bookMakerOdds.push({
                marketId: br.marketId ? br.marketId : '-1',
                marketName: br.marketName ? br.marketName : 'Bookmaker',
                runners: bmRunners,
                status: br.status ? br.status : 'OPEN',
              });
          }
        }
        bookMakerOdds.sort((a, b) => {
          const aDesc = a.marketName;
          const bDesc = b.marketName;
          if (aDesc > bDesc) return 1;
          else if (aDesc < bDesc) return -1;
          else return 0;
        });
        marketsMap[eventId].bookmakers = bookMakerOdds;
        marketsMap[eventId].enableBookmaker = action.payload.enableBookmaker;
        return {
          ...state,
          secondaryMarketsMap: marketsMap,
        };
      }
    }

    case UPDATE_SECONDARY_MARKETS: {
      const eventId: string = action.payload.eventId;
      const marketsMap = { ...state.secondaryMarketsMap };

      // Add event data
      if (action.payload.eventId) {
        if (!marketsMap[eventId]) {
          marketsMap[eventId] = {
            bookmakers: [],
            enableBookmaker: false,
            fancyMarkets: [],
            enableFancy: false,
          };
        }
        // Set BookmakerOdds Data.
        const bookMakerOddsData = action.payload.bookmakerOddsData;
        let bookMakerOdds: BookmakerDTO[] = [];
        if (bookMakerOddsData && bookMakerOddsData.length > 0) {
          for (let br of bookMakerOddsData) {
            let bmRunners: BookmakerRunnerDTO[] = [];
            for (let b of br.runners) {
              bmRunners.push({
                runnerId: b.runnerId ? b.runnerId : '',
                runnerName: b.runnerName ? b.runnerName : '',
                backPrice: b.backPrices[0]?.price,
                backSize: b.backPrices[0]?.size,
                layPrice: b.layPrices[0]?.price,
                laySize: b.layPrices[0]?.size,
                status: b.status ? b.status : '',
                sort: b.sort ? Number(b.sort) : 0,
              });
            }
            bmRunners.sort((a, b) => {
              if (a?.sort - b?.sort != 0) {
                return a?.sort - b?.sort;
              }
              const aDesc = a.runnerId;
              const bDesc = b.runnerId;
              if (aDesc > bDesc) return 1;
              else if (aDesc < bDesc) return -1;
              else return 0;
            });
            bookMakerOdds.push({
              marketId: br.marketId ? br.marketId : '-1',
              marketName: br.marketName ? br.marketName : 'Bookmaker',
              runners: bmRunners,
              status: br.status ? br.status : 'OPEN',
            });
          }
        } else if (marketsMap[eventId].bookmakers[0]) {
          bookMakerOdds = marketsMap[eventId].bookmakers;
          for (let bm of bookMakerOdds) {
            for (let br of bm.runners) {
              br.backPrice = '0';
              br.layPrice = '0';
            }
          }
        }
        bookMakerOdds.sort((a, b) => {
          const aDesc = a.marketName;
          const bDesc = b.marketName;
          if (aDesc > bDesc) return 1;
          else if (aDesc < bDesc) return -1;
          else return 0;
        });
        marketsMap[eventId].bookmakers = bookMakerOdds;
        marketsMap[eventId].enableBookmaker = action.payload.enableBookmaker;

        // Set Fancy markets data
        const fancyOddsData = action.payload.sessionOddsData;
        let fancyOdds: FancyMarketDTO[] = [];
        if (fancyOddsData && fancyOddsData.length > 0) {
          for (let f of fancyOddsData) {
            fancyOdds.push({
              marketId: f.marketId ? f.marketId : '',
              marketName: f.marketName ? f.marketName : '',
              status: f.status ? f.status : '',
              sort: f.sort ? Number(f.sort) : 0,
              layPrice: f.noValue
                ? f.marketType === 'fancy3' || f.marketType === 'odd-even'
                  ? f.noValue.toFixed(2)
                  : f.noValue
                : null,
              backPrice: f.yesValue
                ? f.marketType === 'fancy3' || f.marketType === 'odd-even'
                  ? f.yesValue.toFixed(2)
                  : f.yesValue
                : null,
              laySize: f.noRate ? f.noRate : null,
              backSize: f.yesRate ? f.yesRate : null,
              category: getFancyCategory(f.category),
            });
          }
        } else if (marketsMap[eventId].fancyMarkets) {
          fancyOdds = marketsMap[eventId].fancyMarkets;
          for (let fMarket of fancyOdds) {
            fMarket.status = 'SUSPENDED';
          }
        }
        // fancyOdds.sort((a, b) => {
        //   const aDesc = a.selectionId;
        //   const bDesc = b.selectionId;
        //   if (aDesc > bDesc) return -1;
        //   else if (aDesc < bDesc) return 1;
        //   else return 0;
        // });
        marketsMap[eventId].fancyMarkets = fancyOdds;
        marketsMap[eventId].enableFancy = action.payload.enableFancy;
      }
      return {
        ...state,
        secondaryMarketsMap: marketsMap,
      };
    }

    // case FETCH_EVENT_FAILED: {
    //   return {
    //     ...state,
    //     // events: {},
    //   };
    // }
    case DISABLE_EVENT_DATA: {
      const sportId = action.payload.sportId;
      const competitionId = action.payload.competitionId;
      const eventId = action.payload.eventId;
      let disableEvent = action.payload.disableEvent;
      if (!disableEvent && state.selectedEvent.id === eventId) {
        disableEvent = true;
      }

      const allEvents = { ...state.events };
      const allSecMarkets = { ...state.secondaryMarketsMap };

      if (allEvents[sportId][competitionId][eventId])
        if (disableEvent) {
          const eData = allEvents[sportId][competitionId][eventId];
          for (let runner of eData.matchOdds?.runners) {
            runner.status = 'SUSPENDED';
          }
          allEvents[sportId][competitionId][eventId] = eData;
        } else {
          delete allEvents[sportId][competitionId][eventId];
        }
      if (allSecMarkets[eventId])
        if (disableEvent) {
          const secMarkets = allSecMarkets[eventId];
          for (let bookmaker of secMarkets?.bookmakers) {
            for (let runner of bookmaker.runners) {
              runner.backPrice = '0';
              runner.backSize = '0';
              runner.layPrice = '0';
              runner.laySize = '0';
            }
          }
          for (let fanctMarket of secMarkets?.fancyMarkets) {
            fanctMarket.status = 'SUSPENDED';
          }
          allSecMarkets[eventId] = secMarkets;
        } else {
          delete allSecMarkets[eventId];
        }

      return {
        ...state,
        events: allEvents,
        secondaryMarketsMap: allSecMarkets,
      };
    }

    case UPDATE_PREMIUM_MARKETS_WS: {
      // const market = action.payload.eventData;
      const premiumSporsMap = { ...state.premiumMarketsMap };

      const key = action.payload.eventId;
      const eventData = action.payload.body;

      if (premiumSporsMap[key]) {
        if (
          premiumSporsMap[key].markets &&
          premiumSporsMap[key].markets.matchOdds.length > 0
        ) {
          for (let market of eventData?.markets?.matchOdds) {
            const index = premiumSporsMap[key].markets.matchOdds.findIndex(
              (x) => x.marketId === market.marketId
            );

            if (index !== -1) {
              premiumSporsMap[key].markets.matchOdds[index] = market;
              // console.log(market);
            }
          }
        }
      }

      return {
        ...state,
        premiumMarketsMap: premiumSporsMap,
      };
    }

    case SET_EXCH_EVENT: {
      return {
        ...state,
        selectedEvent: action.payload,
      };
    }

    case RESET_EVENTS: {
      return {
        ...state,
        events: {},
      };
    }

    case UPDATE_SCORECARD: {
      return {
        ...state,
        scorecard: action.payload,
      };
    }

    case UPDATE_BINARY_MARKETS: {
      return {
        ...state,
        binaryMarkets: action.payload,
      };
    }
    case UPDATE_PREMIUM_MARKETS: {
      const eventData = action.payload.eventData;
      const premiumMarketsMap = { ...state.premiumMarketsMap };
      premiumMarketsMap[action.payload.eventId] = eventData;
      return {
        ...state,
        premiumMarketsMap: premiumMarketsMap,
      };
    }
    default:
      return state;
  }
};

export default exchangeSportsReducer;
