export const ContactList: { [key: string]: string } = {
  playexch247: '7450010508',
  corbet247: '9010330000',
};

export const ShowCasinoModal: { [key: string]: boolean } = {
  DhanEXCH: false,
};

export const ShowThemeToggle: { [key: string]: boolean } = {
  Bet60Exchange: false,
};

export const UpdateContactWhiteLabels: { [key: string]: boolean } = {
  baazifair: true,
  sky11: true,
  corbet247: true,
  playexch247: true,
};

export const ShowThemelogo: { [key: string]: boolean } = {
  betfair: true,
  windaddy: true,
  jadu: true,
  a2zbets: true,
  shettyonline: true,
};

export const ShowlogoAlignment: { [key: string]: boolean } = {
  fairwins77: true,
};

export const DefaultTheme: { [key: string]: string } = {
  sky11: 'light',
  bigb11: 'green1',
  titosexch: 'blue',
  sky11bet: 'blue',
  dubsky11: 'blue',
  playsky11: 'blue',
  skyx11: 'blue',
  starcoinexchange: 'blue',
  maxexch11: 'blue',
  fair11: 'light',
  cbtfsky11: 'purple',
  skythunders: 'blue',
  playbhai: 'blue',
  playexch247: 'dark',
  playbetexch: 'dark',
  indian11: 'dark',
  corbet247: 'light',
  cherrysky11: 'blue',
  '786sky': 'green1',
  uniexch: 'green1',
  dafabet11: 'dark',
  baazifair: 'green',
  krishna11: 'orange',
  sarkar11: 'orange',
  betfair: 'light',
  windaddy: 'light',
  jadu: 'brown',
  rock8exch: 'blue',
  fairwins77: 'green',
  shuffleluck9: 'green1',
  tsunami9: 'green1',
  a2zbets: 'orange',
  shettyonline: 'dark',
};
