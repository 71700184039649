// Web banners
// Mobile banners
import { CONFIG_PERMISSIONS } from './ConfigPermission';
import bk from '../../src/assets/images/home/casino/bk.png';
import autoroulette from '../../src/assets/images/home/casino/autoroulette.png';
import dragontiger from '../../src/assets/images/home/casino/dragontiger.png';
import rulet from '../../src/assets/images/home/casino/rulet.png';
import baccarat from '../../src/assets/images/home/casino/baccarat.png';
import cards from '../../src/assets/images/home/casino/cards.png';
import hounds from '../../src/assets/images/home/casino/hounds.png';
import roulette from '../../src/assets/images/home/casino/roulette.png';
import betcity from '../../src/assets/images/home/casino/betcity.png';
import blackjack from '../../src/assets/images/home/casino/blackjack.png';
import fortune from '../../src/assets/images/home/casino/fortune.png';
import peakbaccrat from '../../src/assets/images/home/casino/peakbaccrat.png';
import homeBanner from '../assets/images/common/home.jpg';
import homeBannerMob from '../assets/images/common/mob-home-banner.jpg';
import Img1 from '../assets/images/casino/cards/1.svg';
import Img2 from '../assets/images/casino/cards/2.svg';
import Img3 from '../assets/images/casino/cards/3.svg';
import Img4 from '../assets/images/casino/cards/4.svg';
import Img5 from '../assets/images/casino/cards/5.svg';
import Img6 from '../assets/images/casino/cards/bombay.svg';
import Img7 from '../assets/images/casino/cards/7.svg';
import Img8 from '../assets/images/casino/cards/11.svg';
import Img9 from '../assets/images/casino/cards/17.svg';
import Img10 from '../assets/images/casino/cards/10.svg';
import Banner from '../assets/images/common/web-banner.jpg';
import BannerMob from '../assets/images/common/mob-banner.jpg';
const HOME_PAGE_HEADERS = {
  featureSports: 'Featured Sports',
  popularGames: 'Popular Games',
  liveGames: 'Live Casino',
};

const HOME_PAGE_IMAGES = {
  blackjack: {
    image: bk,
    id: '200242:ezg_baccaratlobby:DC:Evolution Gaming',
    text: 'Blackjack',
  },
  autoroulette: {
    image: autoroulette,
    id: '100082:ezg_ultimate_roulette:EZUGI:Ezugi',
    text: 'Auto Roulette',
  },
  baccaratLive: {
    image: baccarat,
    id: '200150:evo_no_commission_baccarat:DC:Evolution Gaming',
    text: 'Baccarat Live',
  },
  rouletteLive: {
    image: rulet,
    id: '200257:ssc_superspadeclublobby:DC:Evolution Gaming',
    text: 'Roulette Live',
  },
  wheelOfFortune: {
    image: fortune,
    id: '201153:bgt_andarbahar:DC:BetGames.TV',
    text: 'Wheel Of Fortune',
  },

  Cards32: {
    image: cards,
    id: '100061:ezg_dragontiger:EZUGI:EZUGI',
    text: '32 Cards',
  },
  dragontiger: {
    image: dragontiger,
    id: '200139:ezg_speedcricketbaccarat:DC:Evolution Gaming',
    text: 'Speed Cricket Baccarat',
    gameName: 'Dragon Tiger',
  },
  houseOfDragons: {
    image: hounds,
    id: '200972:ebt_ebetlobby:DC:Kiron',
    text: 'eBET',
  },
  roulette: {
    image: roulette,
    id: '400006:ezg_speedroulette:AWC:SEXYBCRT',
    text: 'Roulette',
  },
  speed_auto_roulette: {
    image: peakbaccrat,
    id: '100035:ezg_speedautoroulette:EZUGI:EZUGI',
    text: 'Speed Auto Roulette',
    gameName: 'poker',
  },
  teen_patti: {
    image: 'https://cdn.dreamcasino.live/ezg_teenpatti.jpg',
    id: '100046:ezg_teenpatti:EZUGI:EZUGI',
    text: 'Teen Patti',
    gameName: 'EZUGI',
  },
  one_day_teen_patti_classic: {
    image: 'https://cdn.dreamcasino.live/ezg_onedayteenpatticlassic.jpg',
    id: '100048:ezg_onedayteenpatticlassic:EZUGI:EZUGI',
    text: 'One Day Teen Patti Classic',
    gameName: 'EZUGI',
  },
  bet_on_teen_patti: {
    image: blackjack,
    id: '200242:ezg_baccaratlobby:DC:Evolution Gaming',
    text: 'Blackjack',
  },
  rct: {
    image: betcity,
    id: '200970:cmg_cricketfevervideoslot:DC:Edge Gaming',
    text: 'virtual cricket',
    gameName: 'evolution',
  },
};

const HOME_PAGE_WEB_BANNERS = [
  {
    image: 'https://sky-asserts.s3.eu-west-2.amazonaws.com/banner1-web.png',
    text: 'WORLD_CUP',
    url: '/',
    configPermission: CONFIG_PERMISSIONS.cricket,
  },
  {
    image: 'https://sky-asserts.s3.eu-west-2.amazonaws.com/banner2-web.png',
    text: 'WORLD_CUP',
    url: '/',
    configPermission: CONFIG_PERMISSIONS.cricket,
  },
  {
    image: 'https://sky-asserts.s3.eu-west-2.amazonaws.com/banner3-web.jfif',
    text: 'WORLD_CUP',
    url: '/',
    configPermission: CONFIG_PERMISSIONS.cricket,
  },
  {
    image: Banner,
    text: 'WORLD_CUP',
    url: '/',
    configPermission: CONFIG_PERMISSIONS.cricket,
  },
];

const WHITE_LABEL_BANNERS = [
  {
    image: 'https://sky-asserts.s3.eu-west-2.amazonaws.com/wl_banner1.png',
    text: 'WORLD_CUP 2021',
    url: `/exchange_sports/cricket`,
    configPermission: CONFIG_PERMISSIONS.sports,
  },
];

const HOME_PAGE_MOB_BANNERS = [
  {
    image: 'https://sky-asserts.s3.eu-west-2.amazonaws.com/banner1-mob.png',
    text: 'WORLD_CUP',
    url: '/',
    configPermission: CONFIG_PERMISSIONS.cricket,
  },
  {
    image: 'https://sky-asserts.s3.eu-west-2.amazonaws.com/banner2-mob.png',
    text: 'WORLD_CUP',
    url: '/',
    configPermission: CONFIG_PERMISSIONS.cricket,
  },
  {
    image: 'https://sky-asserts.s3.eu-west-2.amazonaws.com/banner3-mob.jfif',
    text: 'WORLD_CUP',
    url: '/',
    configPermission: CONFIG_PERMISSIONS.cricket,
  },

  {
    image: BannerMob,
    text: 'WORLD_CUP',
    url: '/',
    configPermission: CONFIG_PERMISSIONS.cricket,
  },
];

const WHITE_LABEL_BANNERS_MOBILE = [
  {
    image: 'https://sky-asserts.s3.eu-west-2.amazonaws.com/wl_banner1.png',
    text: 'WORLD_CUP 2021',
    url: `/exchange_sports/cricket`,
    configPermission: CONFIG_PERMISSIONS.sports,
  },
];

const EVENT_PAGE_IMAGES = {
  cricket: {
    image: homeBanner,
    text: 'Cricket',
    url: '/exchange_sports/cricket',
  },
  soccer: {
    image: homeBanner,
    text: 'Soccer',
    url: '/exchange_sports/football',
  },
  Football: {
    image: homeBanner,
    text: 'Football',
    url: '/exchange_sports/football',
  },
  tennis: {
    image: homeBanner,
    text: 'Tennis',
    url: '/exchange_sports/tennis',
  },
};

export const CASINO_WEB_NAV_PROVIDERS = [
  {
    image: Img1,
    text: 'Royal Casino',
    url: '/casino/royal gaming',
  },
  {
    image: Img2,
    text: 'Betsoft',
    url: '/casino/betsoft',
  },
  {
    image: Img3,
    text: 'EZUGI',
    url: '/casino/ezugi',
  },
  {
    image: Img4,
    text: 'Supernowa',
    url: '/casino/supernowa',
  },
  {
    image: Img5,
    text: 'One Touch Casino',
    url: '/casino/evolution gaming',
  },
  {
    image: Img6,
    text: 'Asia Gaming Casino',
    url: '/casino/bombay live',
  },
  {
    image: Img7,
    text: 'pragmatic',
    url: '/casino/Pragmatic Play Live',
  },
  {
    image: Img8,
    text: 'Asia Casino',
    url: '/casino/sexybcrt',
  },
  {
    image: Img9,
    text: 'Casino',
    url: '/casino/jili gaming',
  },
  {
    image: Img10,
    text: 'removo-mob',
    url: '/casino/betGames.tv',
  },
];
export default {
  HOME_PAGE_HEADERS,
  HOME_PAGE_IMAGES,
  WHITE_LABEL_BANNERS,
  WHITE_LABEL_BANNERS_MOBILE,
  HOME_PAGE_WEB_BANNERS,
  HOME_PAGE_MOB_BANNERS,
  EVENT_PAGE_IMAGES,
};
