import { BRAND_DOMAIN } from '../../constants/Branding';

export const DevUrlsMap = {
  REACT_APP_HOST_URL: `https://dev.sky11.com/api/v1`,
  REACT_APP_REST_API_URL: `https://dev-user-api.sky11.com/api/v1`,
  REACT_APP_REST_SVLS_API_URL: `https://dev-user-api.sky11.com`,
  REACT_APP_REPORTING_API_URL: `https://dev-reporting.sky11.com`,
  BINARY_WEBSOCKET_URL: `https://user-api.sky11.com/hypex-ws`,
  REACT_APP_SPORT_FEED_URL: `https://stage-api.mysportsfeed.io/api/v1`,
};

export const StageUrlsMap = {
  REACT_APP_HOST_URL: `https://stage.sky11.com`,
  REACT_APP_REST_API_URL: `https://stage-user-api.sky11.com/api/v1`,
  REACT_APP_REST_SVLS_API_URL: `https://stage-user-api.sky11.com`,
  REACT_APP_REPORTING_API_URL: `https://stage-reporting.sky11.com`,
  BINARY_WEBSOCKET_URL: `https://user-api.sky11.com/hypex-ws`,
  REACT_APP_SPORT_FEED_URL: `https://stage-api.mysportsfeed.io/api/v1`,
};

export const ProdUrlsMap = {
  REACT_APP_HOST_URL: `https://${BRAND_DOMAIN}`,
  REACT_APP_REST_API_URL: `https://api.${BRAND_DOMAIN}/api/v1`,
  REACT_APP_REST_SVLS_API_URL: `https://api.${BRAND_DOMAIN}`,
  REACT_APP_REPORTING_API_URL: `https://reporting.${BRAND_DOMAIN}`,
  BINARY_WEBSOCKET_URL: `https://user-api.${BRAND_DOMAIN}/hypex-ws`,
  REACT_APP_SPORT_FEED_URL: `https://api.mysportsfeed.io/api/v1`,
};

export const EnvUrlsMap = {
  development: DevUrlsMap,
  stage: StageUrlsMap,
  production: ProdUrlsMap,
};

export function getBaseUrl(env: string, url: string) {
  return EnvUrlsMap[env][url];
}
