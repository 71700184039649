
export const SET_MULTIMARKET_EVENT_DATA = 'SET_MULTIMARKET_EVENT_DATA';
export const UPDATE_MULTIMARKET_SECONDARY_MARKETS =
  'UPDATE_MULTIMARKET_SECONDARY_MARKETS';
export const UPDATE_MULTIMARKET_FANCY_MARKETS =
  'UPDATE_MULTIMARKET_FANCY_MARKETS';
export const UPDATE_MULTIMARKET_SECONDARY_MATCH_ODDS =
  'UPDATE_MULTIMARKET_SECONDARY_MATCH_ODDS';
export const UPDATE_MULTIMARKET_BOOKMAKER_MARKETS =
  'UPDATE_MULTIMARKET_BOOKMAKER_MARKETS';
