import {
  SET_LIVESTREAM_URL,
  SET_PLAY_STREAM,
  SET_ALLOWED_CONFIG,
  ENABLE_COMMISSION,
  TOGGLE_DARK_MODE,
} from './commonActionTypes';
import { CommonState } from '../../models/CommonState';
import { CONFIG_PERMISSIONS } from '../../constants/ConfigPermission';
import { BRAND_NAME } from '../../constants/Branding';
import { DefaultTheme } from '../../constants/WhitelabelConfig';

type Action = {
  type: string;
  payload: any;
};

const initialState: CommonState = {
  prefersDark: localStorage.getItem('userTheme')
    ? localStorage.getItem('userTheme')
    : DefaultTheme[BRAND_NAME.toLowerCase()],
  streamUrl: '',
  playStream: false,
  allowedConfig: CONFIG_PERMISSIONS.sports + CONFIG_PERMISSIONS.casino,
  commissionEnabled: false,
};

const commonReducer = (state = initialState, action: Action): CommonState => {
  switch (action.type) {
    case TOGGLE_DARK_MODE:
      return {
        ...state,
        prefersDark: action.payload,
      };
    case SET_PLAY_STREAM:
      return {
        ...state,
        playStream: action.payload,
      };
    case SET_LIVESTREAM_URL:
      return {
        ...state,
        streamUrl: action.payload,
      };
    case SET_ALLOWED_CONFIG:
      return {
        ...state,
        allowedConfig: action.payload,
      };
    case ENABLE_COMMISSION:
      return {
        ...state,
        commissionEnabled: action.payload,
      };
    default:
      return state;
  }
};

export default commonReducer;
