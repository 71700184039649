import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { IonSpinner } from '@ionic/react';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Button from '@material-ui/core/Button';
import { useFormik } from 'formik';
import * as Yup from 'yup';
// import axios from 'axios';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import savebtn from '../../assets/images/common/login.svg';

import './LoginForm.scss';
import {
  fetchBalance,
  loginSuccess,
  loginFailed,
  requestStart,
  requestEnd,
} from '../../store';
import { RootState } from '../../models/RootState';
import { IonLabel, IonIcon } from '@ionic/react';
import API from '../../api';
import ForgotPwdForm from '../ForgotPassword/ForgotPassword';
import Modal from '../Modal/Modal';
// import ReCAPTCHA from 'react-google-recaptcha';
// Password Encryptioon
import JSEncrypt from 'jsencrypt';
import { RsaPublicKey } from '../../constants/RSAPublicKey';
import { LOGO_ICONS } from '../../constants/CommonConstants';
import API_V2 from '../../api-services/svls-api';
import { BRAND_DOMAIN, BRAND_DOMAIN_SKIN_URL, BRAND_NAME } from '../../constants/Branding';
import axios from 'axios';

type StoreProps = {
  loginSuccess: Function;
  loginFailed: Function;
  requestStart: Function;
  requestEnd: Function;
  fetchBalance: Function;
  prefersDark: string;
};

type LoginProps = StoreProps & {
  errorMsg: string;
  loading: boolean;
  loggedIn: boolean;
  modalCloseHandler?: Function;
  redirectUrl?: string;
};

const LoginForm: React.FC<LoginProps> = (props) => {
  const {
    errorMsg,
    loading,
    loggedIn,
    loginSuccess,
    loginFailed,
    requestStart,
    requestEnd,
    fetchBalance,
    modalCloseHandler,
    redirectUrl,
    prefersDark,
  } = props;

  const [showPassword, setShowPassword] = useState(false);
  const [useAuthenticator, setUseAuthenticator] = useState<boolean>(false);
  const [showForgotPwdModal, setShowForgotPwdModal] = useState(false);
  const [demoLoading, setDemoLoading] = useState(false);
  const [loadLogin, setLoadLogin] = useState(false);
  const [demoLoginEnabled, setDemoLoginEnabled] = useState<boolean>(false);

  let history = useHistory();
  const { search } = useLocation();
  const authToken = new URLSearchParams(search).get('authToken');
  useEffect(() => {
    if (authToken) {
      const username = authToken?.split('.')?.[1];
      let uname = JSON.parse(window?.atob(username)).sub;
      sessionStorage.setItem('username', uname);
      sessionStorage.setItem('jwt_token', authToken);
      loginSuccess(authToken);
      history.push('/home');
      fetchBalance();
    }
  }, [authToken]);

  const formik = useFormik({
    initialValues: { username: '', password: '', code: '' },
    validationSchema: Yup.object({
      username: Yup.string().required('Required'),
      password: Yup.string(),
      code: Yup.string(),
    }),

    onSubmit: async (values) => {
      requestStart();
      try {
        const loginRequest = {
          username: values.username.toLowerCase(),
          password: values.password.trim(),
        };

        const response = await API_V2.post('/account/v2/login', loginRequest, {
          responseType: 'text',
        });
        requestEnd();

        sessionStorage.setItem('username', values.username.toLowerCase());
        sessionStorage.setItem('jwt_token', response.data);
        localStorage.removeItem(
          `multiMarket_${values?.username?.toLowerCase()}`
        );

        loginSuccess(response.data);

        let claim = response.data.split('.')[1];
        let permission = JSON.parse(window.atob(claim)).perm;
        let status = JSON.parse(window.atob(claim)).sts;

        if (status === 2) {
          history.replace('/terms-and-conditions');
        } else if (status === 4) {
          history.replace('/reset-password');
        } else if ((permission & 2) !== 0) {
          history.replace('/platform_admin/house');
        }

        if (redirectUrl) {
          history.push(redirectUrl);
        } else {
          if((BRAND_DOMAIN?.toLocaleLowerCase() === 'sky11.live')||(BRAND_DOMAIN?.toLocaleLowerCase() === 'sky11.com') || (BRAND_DOMAIN?.toLocaleLowerCase() === 'sky11.asia')){
            window.open(
              `https://s.${BRAND_DOMAIN_SKIN_URL}/login?authToken=${sessionStorage.getItem(
                'jwt_token'
              )}`,
              '_self'
            )
          }else{
              history.push('/home')
          }
          
        }
      } catch (err) {
        loginFailed(err?.response?.data?.message);
      }
    },
  });

  const handleDemoLogin = async () => {
    setDemoLoading(true);
    setLoadLogin(false);
    requestStart();
    try {
      const response = await API_V2.post('/account/v2/login-demo');
      setDemoLoading(false);
      if (response.status == 200) {
        requestEnd();

        let claim = response.data.split('.')[1];
        sessionStorage.setItem('username', JSON.parse(window.atob(response.data.split('.')[1])).sub);
        sessionStorage.setItem('jwt_token', response.data);
        localStorage.removeItem(`multiMarket_${JSON.parse(window.atob(response.data.split('.')[1])).sub.toLowerCase()}`);

        loginSuccess(response.data);

        sessionStorage.setItem('aid', JSON.parse(window.atob(claim)).aid);

        if (redirectUrl) {
          history.push(redirectUrl);
        } else {
          history.push('/home');
        }
      }
    } catch (err) {
      setDemoLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    isDemoLoginEnabled()
  },[]);

  const isDemoLoginEnabled = async () => {
    try {
      const response = await API_V2.get(`/account/v2/books/${BRAND_DOMAIN}`);
      if (response.status === 200) {
        setDemoLoginEnabled(response.data.demo_user_enabled);
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (loggedIn) {
      fetchBalance();
    }
  }, [fetchBalance, loggedIn]);

  const showPasswordClickHandler = () => {
    setShowPassword(!showPassword);
  };

  return (
    <div className="login-form-page">
      <div className="title">User Sign-In</div>

      <div className="title-row">
        <IonIcon src={LOGO_ICONS[prefersDark]} className="logo" />
      </div>

      <form
        onSubmit={formik.handleSubmit}
        className="login-form-ctn"
        autoComplete="off"
      >
        <p className="info">
          {
            BRAND_NAME.toLocaleLowerCase() === "earthexchg11" ?
              "Hey Champ, enter your details to sign into your account"
              : "Hey, Enter your details to get sign in to your account"
          }
        </p>

        {errorMsg !== '' ? (
          <span className="login-err-msg">{errorMsg}</span>
        ) : null}
        <span className="usr-input">
          {/* <IonLabel className="input-label">Username</IonLabel> */}
          <TextField
            className="login-input-field user-name"
            type="text"
            name="username"
            variant="outlined"
            placeholder="Username"
            error={
              formik.touched.username && formik.errors.username ? true : false
            }
            helperText={
              formik.touched.username && formik.errors.username
                ? formik.errors.username
                : null
            }
            {...formik.getFieldProps('username')}
          />
        </span>

        <span className="pwd-input">
          {/* <IonLabel className="input-label">Password</IonLabel> */}
          <FormControl
            className="pwd-field"
            variant="outlined"
            placeholder="Password"
            error={
              formik.touched.password && formik.errors.password ? true : false
            }
          >
            <OutlinedInput
              id="standard-adornment-password"
              type={showPassword ? 'text' : 'password'}
              name="password"
              placeholder="Password"
              {...formik.getFieldProps('password')}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={showPasswordClickHandler}
                    onMouseDown={showPasswordClickHandler}
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {formik.touched.password && formik.errors.password ? (
              <FormHelperText error id="my-helper-text">
                {formik.errors.password}
              </FormHelperText>
            ) : null}
          </FormControl>
        </span>

        {useAuthenticator ? (
          <span className="code-input">
            <IonLabel className="input-label">Code</IonLabel>
            <TextField
              className="login-input-field user-name"
              type="text"
              name="username"
              variant="outlined"
              {...formik.getFieldProps('code')}
            />
          </span>
        ) : null}

        <div className='login-and-demo-login'>
          <Button
            className={demoLoginEnabled? "login-form-btn":"login-form-btn-without-demologin"}
            color="primary"
            endIcon={
              loading && loadLogin ? <IonSpinner name="lines-small" /> : ''
            }
            type="submit"
            variant="contained"
          >
            <IonIcon src={savebtn} className="icon" />
            Sign In
          </Button>
          {demoLoginEnabled ?
            <Button
              className="login-form-btn"
              color="primary"
              endIcon={demoLoading ? <IonSpinner name="lines-small" /> : ''}
              onClick={handleDemoLogin}
              variant="contained"
            >
              {!demoLoading? <IonIcon src={savebtn} className="icon" /> : null}
              Demo Sign In
            </Button>
            : null} 
        </div>

        <div className="back">
          {' '}
          <NavLink to="/home" className="navlink">
            Back to Home{' '}
          </NavLink>
        </div>

        <Modal
          open={showForgotPwdModal}
          closeHandler={() => setShowForgotPwdModal(false)}
          title="Forgot Password"
          size="xs"
        >
          <ForgotPwdForm closeHandler={() => setShowForgotPwdModal(false)} />
        </Modal>
      </form>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    loading: state.auth.loading,
    loggedIn: state.auth.loggedIn,
    errorMsg: state.auth.loginError,
    prefersDark: state.common.prefersDark,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    fetchBalance: () => dispatch(fetchBalance()),
    loginSuccess: (payload) => dispatch(loginSuccess(payload)),
    loginFailed: (err: string) => dispatch(loginFailed(err)),
    requestStart: () => dispatch(requestStart()),
    requestEnd: () => dispatch(requestEnd()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
