import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { NavLink, useHistory, useLocation } from 'react-router-dom';
import { IonIcon } from '@ionic/react';
import KeyboardArrowDown from '@material-ui/icons/ExpandMore';
import WhatsApp from '../../assets/images/icons/whatsapp.png';

import {
  AppBar,
  Button,
  Popover,
  Toolbar,
  Tooltip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
} from '@material-ui/core';
import { RootState } from '../../models/RootState';
import {
  fetchBalance,
  logout,
  toggleDarkMode,
  getCurrencyTypeFromToken,
  fetchEventsByCompetition,
  setCompetition,
  fetchCompetitions,
  setEventType,
  setExchEvent,
} from '../../store';
import { LOGO_ICONS } from '../../constants/CommonConstants';
import BetsListPopper from '../BetsListPopper/BetsListPopper';
import moment from 'moment';
import { useInterval } from '../../hooks/useInterval';
import { ShowThemeToggle, ShowlogoAlignment } from '../../constants/WhitelabelConfig';
import {
  BRAND_DOMAIN_SKIN_URL,
  BRAND_NAME,
  PROVIDER_ID,
} from '../../constants/Branding';
import { CURRENCY_TYPE_FACTOR } from '../../constants/CurrencyTypeFactor';
import MobileSideNav from './MobileSideNav/MobileSideNav';
import notificationIcon from '../../assets/images/common/icons/notification.svg';
import moonIcon from '../../assets/images/common/icons/moon.svg';
import DashboardView from '../../components/Dashboard/Dashboard';
import BlockIcon from '@material-ui/icons/Block';
import BalanceIcon from '@material-ui/icons/AccountBalanceWalletRounded';
import './Header.scss';
import API_V2 from '../../api-services/svls-api';
import { EventDTO } from '../../models/common/EventDTO';
import { SelectedObj } from '../../models/ExchangeSportsState';
import { SPToBFIdMap, demoUserPrefix } from '../../util/stringUtil';
import lotus_logo from '../../assets/images/common/lotus.svg';
import diamond_logo from '../../assets/images/common/diamond.svg';
import ssky_logo from '../../assets/images/common/s.sky11.svg';
import { AuthResponse } from '../../models/api/AuthResponse';
type StoreProps = {
  balance: number;
  exposure: number;
  allowedConfig: number;
  commissionEnabled: boolean;
  fetchBalance: Function;
  loggedIn: boolean;
  logout: Function;
  prefersDark: string;
  setEventType: (event: SelectedObj) => void;
  setExchEvent: (event: SelectedObj) => void;
  setCompetition: (event: SelectedObj) => void;
  fetchCompetitions: (eventTypeId: string) => void;
  fetchEventsByCompetition: (
    eventTypeId: string,
    competitionId: string
  ) => string;
  toggleDarkMode: (val: string) => void;
};

const Header: React.FC<StoreProps> = (props) => {
  const [showSearch, setShowSearch] = useState<boolean>(false);
  const [liveEventList, setLiveEventList] = useState([]);
  const [popOver, setPopOver] = useState<boolean>(false);
  const [value, setValue] = useState<string>();
  const history = useHistory();
  const [regWhatsappLink, setRegWhatsappLink] = useState<string>();
  const [showPopover, setShowPopover] = useState<{
    open: boolean;
    event: HTMLElement | undefined;
  }>({
    open: false,
    event: undefined,
  });
  const [showNewPotal, setShowNewPotal] = useState<{
    open: boolean;
    event: HTMLElement | undefined;
  }>({
    open: false,
    event: undefined,
  });
  const [themeModalOpen, setThemeModalOpen] = useState<{
    open: boolean;
    event: HTMLElement | undefined;
  }>({
    open: false,
    event: undefined,
  });
  const [showBetsListPopover, setShowBetsListPopover] = useState<{
    open: boolean;
    event: HTMLElement | undefined;
  }>({
    open: false,
    event: undefined,
  });
  const [localTime, setLocalTime] = useState(moment().format('hh:mm:ss A'));
  let eventListRef = useRef<HTMLInputElement>();
  const {
    balance,
    exposure,
    fetchBalance,
    loggedIn,
    setExchEvent,
    fetchCompetitions,
    setCompetition,
    toggleDarkMode,
    prefersDark,
    setEventType,
  } = props;
  const cFactor = CURRENCY_TYPE_FACTOR[getCurrencyTypeFromToken()];
  const location = useLocation();

  useEffect(() => {
    if (loggedIn) {
      fetchBalance();
    }
  }, [fetchBalance, loggedIn]);

  useInterval(() => {
    if (loggedIn) {
      setLocalTime(moment().format('hh:mm:ss A'));
    }
  }, 1000);

  const popoverDismissHandler = () => {
    setShowPopover({ open: false, event: undefined });
    setShowNewPotal({
      open: false,
      event: undefined,
    });
    setThemeModalOpen({ open: false, event: undefined });
  };

  const setThemeHandler = (data) => {
    localStorage.setItem('userTheme', data);
    toggleDarkMode(data);
  };

  const handleInputQuery = async (query: string) => {
    if (query && query.length > 0) {
      setValue(query);
      try {
        const response = await API_V2.get(
          '/catalog/v2/sports-feed/sports/events:search',
          {
            params: {
              providerId: PROVIDER_ID,
              query: query,
            },
          }
        );
        if (response.status === 200) {
          setLiveEventList(response.data);
          setPopOver(true);
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      setPopOver(false);
      setValue('');
    }
  };

  const handleEvent = async (event: EventDTO) => {
    setPopOver(false);
    setValue('');
    const competitionSlug = event.competitionName
      ? event.competitionName
        .toLocaleLowerCase()
        .replace(/[^a-z0-9]/g, ' ')
        .replace(/ +/g, ' ')
        .trim()
        .split(' ')
        .join('-')
      : 'league';

    setEventType({
      id: event.sportId?.includes(':')
        ? SPToBFIdMap[event.sportId]
        : event.sportId,
      name: event.sportName.toLocaleLowerCase(),
      slug: event.sportName.toLowerCase(),
    });
    fetchCompetitions(
      event.sportId?.includes(':') ? SPToBFIdMap[event.sportId] : event.sportId
    );
    setCompetition({
      id: event.competitionId,
      name: event.competitionName,
      slug: competitionSlug,
    });
    setExchEvent({
      id: event.eventId,
      name: event.eventName,
      slug: event.eventSlug,
    });

    if (event.sportId !== '7') {
      history.push(
        `/exchange_sports/${event.sportName.toLowerCase()}/${competitionSlug}/${getCompetitionSlug(
          event.eventName
        )}/${btoa(
          `${event.sportId?.includes(':')
            ? SPToBFIdMap[event.sportId]
            : event.sportId
          }:${event.competitionId.includes(':')
            ? event.competitionId.split(':').join('_')
            : event.competitionId
          }:${event.eventId.includes(':')
            ? event.eventId.split(':').join('_')
            : event.eventId
          }`
        )}`
      );
    } else {
      history.push(`/exchange_sports/${getCompetitionSlug(event.sportName)}`);
    }
  };

  const getCompetitionSlug = (competitionName: string) => {
    return competitionName
      .toLocaleLowerCase()
      .replace(/[^a-z0-9]/g, ' ')
      .replace(/ +/g, ' ')
      .trim()
      .split(' ')
      .join('-');
  };

  const handleRemoveQuery = async () => {
    setValue('');
    setPopOver(false);
  };
  useEffect(() => {
    let handler = (e: any) => {
      if (!eventListRef.current?.contains(e.target)) {
        setPopOver(false);
      }
    };
    document.addEventListener('mousedown', handler);

    return () => {
      document.removeEventListener('mousedown', handler);
    };
  });

  const getContactDetails = async () => {
    let hostname: string[] = window.location.hostname.split('.');
    let DOMAIN_URL = hostname[hostname?.length - 2] + '.' + hostname[hostname.length - 1];

    try {
      const response: AuthResponse = await API_V2.get(`/account/v2/books/${DOMAIN_URL}/contact-list`, {
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 200) {
        response.data.map(i => {
          if (i.contactType === "REGISTRATION_WHATSAPP_LINK" && i.details !== '') {
            let checkLink = i.details;
            if ((checkLink.indexOf('https://') > -1) || (checkLink.indexOf('http://') > -1)) {
              setRegWhatsappLink(i.details);
            } else {
              setRegWhatsappLink(`http://api.whatsapp.com/send?phone=${i.details}`);
            }
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getContactDetails();
  }, []);
  
  return (
    <>
      <AppBar
        position="static"
        className={
          history.location.pathname.startsWith('/casino') ||
            history.location.pathname.startsWith('/poker')
            ? 'hidden-header main-header-ctn ds-view-ctn mob-header-ctn'
            : 'main-header-ctn ds-view-ctn mob-header-ctn'
        }
      >
        <Toolbar className="header-row" variant="dense">
          <div className="branding-ctn">
            <MobileSideNav />

            <div
              className="site-title"
              slot="start"
              onClick={(e) => history.push('/home')}
            >
              <img
                className={ShowlogoAlignment[BRAND_NAME.toLocaleLowerCase()]?"logo small_screen_logo":'logo'}
                src={LOGO_ICONS[prefersDark ? prefersDark : 'dark']}
              />
            </div>
            <div className="search-bar" ref={eventListRef}>
              <div className="search-and-remove">
                <input
                  type="search"
                  className="search-input-bar"
                  onChange={(e) => handleInputQuery(e.target.value)}
                  onFocus={(e) => {
                    if (value && value.length > 0) setPopOver(true);
                  }}
                  placeholder="Search Events"
                  value={value}
                />

                {!!value && (
                  <div className="removeQuery" onClick={handleRemoveQuery}>
                    x
                  </div>
                )}
              </div>

              {popOver && (
                <div className="eventList">
                  {liveEventList.map((indEvent) => (
                    <div
                      className="individEvent"
                      key={indEvent.eventId}
                      onClick={(e) => handleEvent(indEvent)}
                    >
                      {moment(indEvent.openDate).format('h:mm A')}{' '}
                      {indEvent.eventName}
                    </div>
                  ))}
                </div>
              )}
            </div>
            {loggedIn ? (
              <div className="live-clock">
                <div className="date">{moment().format('MMM D, YYYY')}</div>
                <div className="time">{localTime}</div>
              </div>
            ) : null}
          </div>

          <div className="header-actions">
            {loggedIn ? (
              <>
                {showSearch ? null : (
                  <>
                    <div className="header-right-actions">
                      <div className="h-list-item web-view">
                        <IonIcon
                          src={notificationIcon}
                          className="notify"
                          onClick={(e) => {
                            history.push('/my-notification');
                          }}
                        />
                      </div>

                      <div className={ShowlogoAlignment[BRAND_NAME.toLocaleLowerCase()]?"btn balance-btn btn_align_logo":'btn balance-btn'}>
                        <Tooltip
                          title="Balance"
                          disableFocusListener
                          disableTouchListener
                        >
                          <BalanceIcon className="block-icon" />
                        </Tooltip>
                        <span className="">Bal: </span>
                        <span className="active-color">
                          {' '}
                          {balance !== null
                            ? Number(balance / cFactor).toFixed(0)
                            : '-.--'}
                        </span>
                      </div>

                      <div className={ShowlogoAlignment[BRAND_NAME.toLocaleLowerCase()]?"btn balance-btn btn_align_logo":'btn balance-btn'}>
                        {' '}
                        <Tooltip
                          title="Exposure"
                          disableFocusListener
                          disableTouchListener
                        >
                          <BlockIcon className="block-icon" />
                        </Tooltip>
                        <span className=""> Exp: </span>
                        <span className="active-color">
                          {exposure !== null
                            ? Number(exposure / cFactor).toFixed(0)
                            : '-.--'}
                        </span>
                      </div>
                      <div
                        className="h-list-item profile-section web-view"
                        onClick={(e) =>
                          setShowPopover({
                            open: true,
                            event: e.currentTarget,
                          })
                        }
                      >
                        <div className="btn-content">
                          <div className="btn-header">
                            <div>{sessionStorage.getItem('username').startsWith(demoUserPrefix)
                              ? "demoUser"
                              : sessionStorage.getItem('username')}</div>
                            <KeyboardArrowDown className="web-view" />
                          </div>
                        </div>
                      </div>
                    </div>
                    {ShowThemeToggle[BRAND_NAME] !== false ? (
                      <>
                        <div
                          className={ShowlogoAlignment[BRAND_NAME.toLocaleLowerCase()]?"switch switch_align_logo" :'switch'}
                          onClick={(e) =>
                            setThemeModalOpen({
                              open: true,
                              event: e.currentTarget,
                            })
                          }
                        >
                          <div className="round">
                            <IonIcon src={moonIcon} className="moon-img" />
                          </div>
                        </div>
                      </>
                    ) : null}
                  </>
                )}
              </>
            ) : (
              <>
                {
                  <>
                    {regWhatsappLink ? (
                      <a
                        className="header-whatsapp-icon"
                        href={regWhatsappLink}
                        target="_blank"
                      >
                        {BRAND_NAME.toLowerCase() === 'jadu' ? (
                          <Button className={'theme-btn'} variant="contained">
                            <div className="lable">REGISTER</div>{' '}
                          </Button>
                        ) : (
                          <img src={WhatsApp} height={28} />
                        )}
                      </a>
                    ) :null}
                  </>
                }

                {!window.location.pathname.includes('/login') ? (
                  <NavLink to="/login">
                    <Button
                      className={'btn'}
                      variant="contained"
                      color="primary"
                    // onClick={loginHandler}
                    >
                      LOGIN
                    </Button>
                  </NavLink>
                ) : null}

                {BRAND_NAME.toLowerCase() === 'sky11' ? (
                  <a href="https://wa.link/sky11" target="_blank">
                    <Button className={ShowlogoAlignment[BRAND_NAME.toLocaleLowerCase()]?'theme-btn' :'theme-btn'} variant="contained">
                      <div className="lable">REGISTER</div>{' '}
                    </Button>
                  </a>
                ) : BRAND_NAME.toLowerCase() === 'uniexch' ? (
                  <a href="https://uniexch.info/index.php" target="_blank">
                    <Button className={'theme-btn'} variant="contained">
                      <div className="lable">SIGN UP</div>{' '}
                    </Button>
                  </a>
                ) : null}

                <>
                  <div
                    className="switch"
                    onClick={(e) =>
                      setThemeModalOpen({
                        open: true,
                        event: e.currentTarget,
                      })
                    }
                  >
                    <div className="round">
                      <IonIcon src={moonIcon} className="moon-img" />
                    </div>
                  </div>
                </>
              </>
            )}
          </div>
        </Toolbar>
      </AppBar>

      <Popover
        open={showPopover.open}
        anchorEl={showPopover.event}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => popoverDismissHandler()}
        className="profile-options"
      >
        <DashboardView setShowPopover={setShowPopover} />
      </Popover>

      <Popover
        open={themeModalOpen.open}
        anchorEl={showPopover.event}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={() => popoverDismissHandler()}
        className="theme-optionslist"
      >
        <div className="theme-drop-down">
          {loggedIn ? (
            <div className="theme-options1">
              <span
                className="circle1"
                onClick={() =>
                  window.open(
                    `https://l.${BRAND_DOMAIN_SKIN_URL}/?authToken=${sessionStorage.getItem(
                      'jwt_token'
                    )}`,
                    '_self'
                  )
                }
              >
                <span className="round">
                  <img src={lotus_logo} className="sport-icon2" />
                </span>
                <div className="label">Lotus</div>
              </span>
              <span
                className="circle1"
                onClick={() =>
                  window.open(
                    `https://s.${BRAND_DOMAIN_SKIN_URL}/login?authToken=${sessionStorage.getItem(
                      'jwt_token'
                    )}`,
                    '_self'
                  )
                }
              >
                <span className="round">
                  <img src={ssky_logo} className="sport-icon" />
                </span>
                <div className="label">SkyExch</div>
              </span>{' '}
              <span
                className="circle1"
                onClick={() =>
                  window.open(
                    `https://d.${BRAND_DOMAIN_SKIN_URL}/?authToken=${sessionStorage.getItem(
                      'jwt_token'
                    )}`,
                    '_self'
                  )
                }
              >
                <span className="round">
                  <img src={diamond_logo} className="sport-icon" />
                </span>
                <div className="label">Diamond</div>
              </span>
            </div>
          ) : null}

          <div className="theme-options">
            <span
              className="circle1"
              onClick={(e) => {
                setThemeHandler('light');
                popoverDismissHandler();
              }}
            >
              <span className="round bg1"></span>
            </span>{' '}
            <span
              className="circle1"
              onClick={(e) => {
                setThemeHandler('dark');
                popoverDismissHandler();
              }}
            >
              <span className="round bg2"></span>
            </span>
            <span
              className="circle1"
              onClick={(e) => {
                setThemeHandler('green');
                popoverDismissHandler();
              }}
            >
              <span className="round bg3"></span>
            </span>
            <span
              className="circle1"
              onClick={(e) => {
                setThemeHandler('purple');
                popoverDismissHandler();
              }}
            >
              <span className="round bg4"></span>
            </span>
            <span
              className="circle1"
              onClick={(e) => {
                setThemeHandler('blue');
                popoverDismissHandler();
              }}
            >
              <span className="round bg5"></span>
            </span>
            {/* <span className="circle1" onClick={() => popoverDismissHandler()}>
            <div className="round1">
              <IonIcon src={HomeImgCancel} className="moon-img" />
            </div>
          </span> */}
            <span
              className="circle1"
              onClick={(e) => {
                setThemeHandler('orange');
                popoverDismissHandler();
              }}
            >
              <span className="round bg6"></span>
            </span>
            <span
              className="circle1"
              onClick={(e) => {
                setThemeHandler('pink');
                popoverDismissHandler();
              }}
            >
              <span className="round bg7"></span>
            </span>
            <span
              className="circle1"
              onClick={(e) => {
                setThemeHandler('pink1');
                popoverDismissHandler();
              }}
            >
              <span className="round bg8"></span>
            </span>
            <span
              className="circle1"
              onClick={(e) => {
                setThemeHandler('green1');
                popoverDismissHandler();
              }}
            >
              <span className="round bg9"></span>
            </span>
            <span
              className="circle1"
              onClick={(e) => {
                setThemeHandler('brown');
                popoverDismissHandler();
              }}
            >
              <span className="round bg10"></span>
            </span>
          </div>
        </div>
      </Popover>
      <Popover
        open={showBetsListPopover.open}
        anchorEl={showBetsListPopover.event}
        className="bets-list-popover"
        onClose={() => setShowBetsListPopover({ open: false, event: null })}
      >
        <BetsListPopper
          closePopover={() =>
            setShowBetsListPopover({ open: false, event: null })
          }
        />
      </Popover>
    </>
  );
};

const mapStateToProps = (state: RootState) => {
  return {
    balance: state.auth.balanceSummary.balance,
    exposure: state.auth.balanceSummary.exposure,
    allowedConfig: state.common.allowedConfig,
    loggedIn: state.auth.loggedIn,
    commissionEnabled: state.common.commissionEnabled,
    prefersDark: state.common.prefersDark,
  };
};

const mapDispatchToProps = (dispatch: Function) => {
  return {
    setEventType: (eType: SelectedObj) => dispatch(setEventType(eType)),
    setCompetition: (competition: SelectedObj) =>
      dispatch(setCompetition(competition)),
    setExchEvent: (event: SelectedObj) => dispatch(setExchEvent(event)),
    fetchCompetitions: (eventTypeId: string) =>
      dispatch(fetchCompetitions(eventTypeId)),
    fetchEventsByCompetition: (etId: string, cId: string) =>
      dispatch(fetchEventsByCompetition(etId, cId, [])),
    fetchBalance: () => dispatch(fetchBalance()),
    logout: () => dispatch(logout()),
    toggleDarkMode: (val: string) => dispatch(toggleDarkMode(val)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
function alpha(white: any, arg1: number) {
  throw new Error('Function not implemented.');
}
