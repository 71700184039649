import {
  SET_MULTIMARKET_EVENT_DATA,
  UPDATE_MULTIMARKET_SECONDARY_MARKETS,
  UPDATE_MULTIMARKET_SECONDARY_MATCH_ODDS,
  UPDATE_MULTIMARKET_BOOKMAKER_MARKETS,
  UPDATE_MULTIMARKET_FANCY_MARKETS,
} from './multimarketActionTypes';
import { MatchOddsRunnerDTO } from '../../models/common/MatchOddsDTO';
import {
  BookmakerDTO,
  BookmakerRunnerDTO,
} from '../../models/common/BookmakerDTO';
import { FancyMarketDTO } from '../../models/common/FancyMarketDTO';
import { ExchangePriceDTO } from '../../models/common/ExchangePriceDTO';

type Action = {
  type: string;
  payload: any;
};

const initialState = {
  multiMarketData: {},
  secondaryMultiMatchOddsMap: {},
  secondaryMultiMarketsMap: {},
};

const getMatchOddsSet = (prices: ExchangePriceDTO[]) => {
  let pricesSet: ExchangePriceDTO[] = [];
  if (prices?.length > 0) {
    for (let i = 0; i < 3; i += 1) {
      if (prices[i]) pricesSet.push(prices[i]);
      else pricesSet.push({ price: null, size: null });
    }
  }
  return pricesSet;
};
const getFancyCategory = (runnerName: string) => {
  return 'session-market';
};

const multiMarketReducer = (state = initialState, action: Action) => {
  switch (action.type) {
    case SET_MULTIMARKET_EVENT_DATA: {
      const { sportId, competitionId, eventId, eventData } = action.payload;
      let data = { ...state.multiMarketData };

      // Add events data
      if (action.payload.eventData) {
        let eData;
        if (data[`${sportId}-${competitionId}-${eventId}`])
          eData = { ...data[`${sportId}-${competitionId}-${eventId}`] };
        else eData = { ...action.payload.eventData };

        if (eData && eData.eventId && eData.eventId !== 'undefined') {
          let homeTeam = eData.homeTeam ? eData.homeTeam : '';
          let awayTeam = eData.awayTeam ? eData.awayTeam : '';
          if (
            homeTeam === '' &&
            awayTeam === '' &&
            (eData.eventName?.toLowerCase().includes(' v ') ||
              eData.eventName?.toLowerCase().includes(' vs '))
          ) {
            homeTeam = eData.eventName?.toLowerCase().includes(' v ')
              ? eData.eventName.split(' v ')[0].trim()
              : eData.eventName.includes(' VS ')
                ? eData.eventName.split(' VS ')[0].trim()
                : eData.eventName.split(' Vs ')[0].trim();
            awayTeam = eData.eventName?.toLowerCase().includes(' v ')
              ? eData.eventName.split(' v ')[1].trim().split(' - ')[0]
              : eData.eventName.includes(' VS ')
                ? eData.eventName.split(' VS ')[1].trim().split(' - ')[0]
                : eData.eventName.includes('Vs')
                  ? eData.eventName.split(' Vs ')[1].trim().split(' - ')[0]
                  : eData.eventName.split(' vs ')[1].trim().split(' - ')[0];
          }

          // Set MatchOdds Data.
          const matchOddsData = action.payload.matchOddsData;
          const runners: MatchOddsRunnerDTO[] = [];
          if (matchOddsData) {
            if (matchOddsData.runners && matchOddsData.runners.length > 0) {
              let i = 0;
              for (let e of matchOddsData.runners) {
                if (e) {
                  let runnerName = e.runnerName ? e.runnerName : e.RunnerName;
                  if (runnerName === undefined) {
                    runnerName = '';
                  }

                  if (!runnerName.toLowerCase().includes('draw') && i === 0) {
                    if (runnerName === '') {
                      runnerName = homeTeam;
                    } else if (runnerName !== awayTeam) {
                      homeTeam = runnerName;
                    }
                  }

                  if (!runnerName.toLowerCase().includes('draw') && i !== 0) {
                    if (runnerName === '') {
                      runnerName = awayTeam;
                    } else if (runnerName !== homeTeam) {
                      awayTeam = runnerName;
                    }
                  }

                  i += 1;
                  runners.push({
                    runnerId: e.runnerId ? e.runnerId : e.runnerId,
                    runnerName: runnerName,
                    backPrices: getMatchOddsSet(e.backPrices),
                    layPrices: getMatchOddsSet(e.layPrices),
                    status: e.status,

                    clothNumber: e?.clothNumber,
                    jockeyName: e?.jockeyName,
                    runnerAge: e?.runnerAge,
                    runnerIcon: e?.runnerIcon,
                    stallDraw: e?.stallDraw,
                    trainerName: e?.trainerName,
                  });
                }
              }
            }
            const bLimits = eData?.matchOdds?.limits;
            eData.matchOdds = {
              marketId: matchOddsData.marketId ? matchOddsData.marketId : '',
              marketName: matchOddsData.marketName
                ? matchOddsData.marketName
                : '',
              // inplay: matchOddsData.inplay ? matchOddsData.inplay : false,
              status: matchOddsData.status ? matchOddsData.status : '',
              runners: runners,
              limits: matchOddsData.limits ? matchOddsData?.limits : bLimits,
            };
          } else {
            let matchOdds = eData.matchOdds;
            if (matchOdds) {
              eData.matchOdds = {
                marketId: matchOdds?.marketId,
                marketName: matchOdds?.marketName,
                // inplay: matchOdds?.inplay,
                status: 'SUSPENDED',
                runners: matchOdds?.runners,
                limits: matchOddsData?.limits,
              };
            } else {
              eData.matchOdds = {
                marketId: '',
                marketName: '',
                // inplay: false,
                status: 'SUSPENDED',
                runners: [],
                limits: null,
              };
            }
          }
          // Set EventData
          eData.homeTeam = homeTeam;
          eData.awayTeam = awayTeam;
          eData.eventSlug = eData.eventSlug
            ? eData.eventSlug
            : eData.eventName
              ? eData.eventName
                .toLowerCase()
                .replace(/[^a-z0-9]/g, ' ')
                .replace(/ +/g, ' ')
                .trim()
                .split(' ')
                .join('-')
              : '';

          data[`${sportId}-${competitionId}-${eventId}`] = eData;
        }
      }
      return {
        ...state,
        multiMarketData: data,
      };
    }

    case UPDATE_MULTIMARKET_SECONDARY_MARKETS: {
      const { sportId, competitionId, eventId } = action.payload;
      const marketsMap = { ...state.secondaryMultiMarketsMap };

      // Add event data
      if (action.payload.eventId) {
        if (!marketsMap[`${sportId}-${competitionId}-${eventId}`]) {
          marketsMap[`${sportId}-${competitionId}-${eventId}`] = {
            bookmakers: [],
            enableBookmaker: false,
            fancyMarkets: [],
            enableFancy: false,
          };
        }

        // Set BookmakerOdds Data.
        const bookMakerOddsData = action.payload.bookmakerOddsData;
        let bookMakerOdds: BookmakerDTO[] = [];
        if (bookMakerOddsData && bookMakerOddsData.length > 0) {
          for (let br of bookMakerOddsData) {
            let bmRunners: BookmakerRunnerDTO[] = [];
            for (let b of br.runners) {
              bmRunners.push({
                runnerId: b.runnerId ? b.runnerId : '',
                runnerName: b.runnerName ? b.runnerName : '',
                backPrice: b.backPrices[0]?.price,
                backSize: b.backPrices[0]?.size,
                layPrice: b.layPrices[0]?.price,
                laySize: b.layPrices[0]?.size,
                status: b.status ? b.status : '',
                sort: b.sort ? Number(b.sort) : 0,
              });
            }
            bmRunners.sort((a, b) => {
              if (a?.sort - b?.sort != 0) {
                return a?.sort - b?.sort;
              }
              const aDesc = a.runnerId;
              const bDesc = b.runnerId;
              if (aDesc > bDesc) return 1;
              else if (aDesc < bDesc) return -1;
              else return 0;
            });
            bookMakerOdds.push({
              marketId: br.marketId ? br.marketId : '-1',
              marketName: br.marketName ? br.marketName : 'Bookmaker',
              runners: bmRunners,
              status: br.status ? br.status : 'OPEN',
            });
          }
        } else if (marketsMap[eventId]?.bookmakers[0]) {
          bookMakerOdds = marketsMap[eventId]?.bookmakers;
          for (let bm of bookMakerOdds) {
            for (let br of bm.runners) {
              br.backPrice = '0';
              br.layPrice = '0';
            }
          }
        }
        bookMakerOdds.sort((a, b) => {
          const aDesc = a.marketName;
          const bDesc = b.marketName;
          if (aDesc > bDesc) return 1;
          else if (aDesc < bDesc) return -1;
          else return 0;
        });
        marketsMap[`${sportId}-${competitionId}-${eventId}`].bookmakers =
          bookMakerOdds;
        marketsMap[`${sportId}-${competitionId}-${eventId}`].enableBookmaker =
          action.payload.enableBookmaker;

        // Set Fancy markets data
        const fancyOddsData = action.payload.sessionOddsData;
        let fancyOdds: FancyMarketDTO[] = [];
        if (fancyOddsData && fancyOddsData.length > 0) {
          for (let f of fancyOddsData) {
            fancyOdds.push({
              marketId: f.marketId ? f.marketId : '',
              marketName: f.marketName ? f.marketName : '',
              status: f.status ? f.status : '',
              sort: f.sort ? Number(f.sort) : 0,
              layPrice: f.layPrice
                ? f.marketType === 'fancy3' || f.marketType === 'odd-even'
                  ? f.layPrice.toFixed(2)
                  : f.layPrice
                : null,
              backPrice: f.backPrice
                ? f.marketType === 'fancy3' || f.marketType === 'odd-even'
                  ? f.backPrice.toFixed(2)
                  : f.backPrice
                : null,
              laySize: f.laySize ? f.laySize : null,
              backSize: f.backSize ? f.backSize : null,
              category: getFancyCategory(f.runnerName),
            });
          }
        } else if (
          marketsMap[`${sportId}-${competitionId}-${eventId}`]?.fancyMarkets
        ) {
          fancyOdds =
            marketsMap[`${sportId}-${competitionId}-${eventId}`].fancyMarkets;
          for (let fMarket of fancyOdds) {
            fMarket.status = 'SUSPENDED';
          }
        }
        marketsMap[`${sportId}-${competitionId}-${eventId}`].fancyMarkets =
          fancyOdds;
        marketsMap[`${sportId}-${competitionId}-${eventId}`].enableFancy =
          action.payload.enableFancy;
      }
      return {
        ...state,
        secondaryMultiMarketsMap: marketsMap,
      };
    }

    case UPDATE_MULTIMARKET_SECONDARY_MATCH_ODDS: {
      const { sportId, competitionId, eventId } = action.payload;
      const marketId: string = action.payload.marketId;
      const allSecMatchOddsMap = { ...state.secondaryMultiMatchOddsMap };
      const matchOddsData = action?.payload?.matchOddsData;

      let runners: MatchOddsRunnerDTO[] = [];
      let runnersData: MatchOddsRunnerDTO[] = allSecMatchOddsMap[
        `${sportId}-${competitionId}-${eventId}-${marketId}`
      ]?.runners?.length
        ? [
          ...allSecMatchOddsMap[
            `${sportId}-${competitionId}-${eventId}-${marketId}`
          ]?.runners,
        ]
        : [];
      if (matchOddsData) {
        if (matchOddsData?.runners && matchOddsData?.runners?.length > 0) {
          let data: any = {};
          for (let e of matchOddsData.runners) {
            if (e) {
              data = runnersData?.find(
                (item) => item?.runnerId === e?.runnerId
              );
              runners.push({
                runnerId: e?.runnerId,
                runnerName: e?.runnerName,
                backPrices: getMatchOddsSet(e.backPrices),
                layPrices: getMatchOddsSet(e.layPrices),
                status: e?.status,
                clothNumber: e?.clothNumber
                  ? e?.clothNumber
                  : data?.clothNumber ?? '',
                jockeyName: e?.jockeyName
                  ? e?.jockeyName
                  : data?.jockeyName ?? '',
                runnerAge: e?.runnerAge ? e?.runnerAge : data?.runnerAge ?? '',
                runnerIcon: e?.runnerIcon
                  ? e?.runnerIcon
                  : data?.runnerIcon ?? '',
                stallDraw: e?.stallDraw ? e?.stallDraw : data?.stallDraw ?? '',
                trainerName: e?.trainerName
                  ? e?.trainerName
                  : data?.trainerName ?? '',
              });
            }
          }
        }

        allSecMatchOddsMap[
          `${sportId}-${competitionId}-${eventId}-${marketId}`
        ] = {
          marketId: matchOddsData.marketId,
          marketName: matchOddsData.marketName,
          marketTime: matchOddsData.marketTime,
          // inplay: matchOddsData.inplay,
          status: matchOddsData.status,
          runners: runners,
          limits: matchOddsData.limits
            ? matchOddsData.limits
            : allSecMatchOddsMap[
              `${sportId}-${competitionId}-${eventId}-${marketId}`
            ]?.limits,
        };
      } else {
        let matchOdds =
          allSecMatchOddsMap[
          `${sportId}-${competitionId}-${eventId}-${marketId}`
          ];
        if (matchOdds) {
          allSecMatchOddsMap[
            `${sportId}-${competitionId}-${eventId}-${marketId}`
          ] = {
            marketId: matchOdds.marketId,
            marketName: matchOdds.marketName,
            marketTime: matchOddsData?.marketTime,
            inplay: matchOdds.inplay,
            status: 'SUSPENDED',
            runners: matchOdds.runners,
            limits: matchOdds.limits,
          };
        } else {
          allSecMatchOddsMap[
            `${sportId}-${competitionId}-${eventId}-${marketId}`
          ] = {
            marketId: '',
            marketName: '',
            // inplay: false,
            status: 'SUSPENDED',
            runners: [],
            limits: null,
          };
        }
      }

      const secMatchOddsMap = { ...state.secondaryMultiMatchOddsMap };
      secMatchOddsMap[`${sportId}-${competitionId}-${eventId}-${marketId}`] =
        allSecMatchOddsMap[
        `${sportId}-${competitionId}-${eventId}-${marketId}`
        ];

      return {
        ...state,
        secondaryMultiMatchOddsMap: secMatchOddsMap,
      };
    }

    case UPDATE_MULTIMARKET_BOOKMAKER_MARKETS: {
      const { sportId, competitionId, eventId } = action.payload;
      //const eventId: string = action.payload.eventId;
      const marketsMap = { ...state.secondaryMultiMarketsMap };
      if (action.payload.eventId) {
        if (!marketsMap[`${sportId}-${competitionId}-${eventId}`]) {
          marketsMap[`${sportId}-${competitionId}-${eventId}`] = {
            ...marketsMap[`${sportId}-${competitionId}-${eventId}`],
            bookmakers: [],
            enableBookmaker: false,
          };
        }

        // Set BookmakerOdds Data.
        const bookMakerOddsData =
          action?.payload?.bookmakerOddsData &&
            Array.isArray(action?.payload?.bookmakerOddsData)
            ? action?.payload?.bookmakerOddsData
            : [action?.payload?.bookmakerOddsData];
        let bookMakerOdds: BookmakerDTO[] = [];
        if (
          marketsMap[`${sportId}-${competitionId}-${eventId}`]?.bookmakers
            ?.length
        )
          bookMakerOdds =
            marketsMap[`${sportId}-${competitionId}-${eventId}`]?.bookmakers;
        if (bookMakerOddsData && bookMakerOddsData.length) {
          for (let br of bookMakerOddsData) {
            let bmRunners: BookmakerRunnerDTO[] = [];
            for (let b of br.runners) {
              bmRunners.push({
                runnerId: b.runnerId ? b.runnerId : '',
                runnerName: b.runnerName ? b.runnerName : '',
                backPrice: b.backPrices[0]?.price,
                backSize: b.backPrices[0]?.size,
                layPrice: b.layPrices[0]?.price,
                laySize: b.layPrices[0]?.size,
                status: b.status ? b.status : '',
                sort: b.sort ? Number(b.sort) : 0,
              });
            }
            bmRunners.sort((a, b) => {
              if (a?.sort - b?.sort != 0) {
                return a?.sort - b?.sort;
              }
              const aDesc = a.runnerId;
              const bDesc = b.runnerId;
              if (aDesc > bDesc) return 1;
              else if (aDesc < bDesc) return -1;
              else return 0;
            });
            let index = bookMakerOdds?.length
              ? bookMakerOdds.findIndex((itm) => itm.marketId === br.marketId)
              : -1;
            if (index > -1)
              bookMakerOdds[index] = {
                marketId: br.marketId ? br.marketId : '-1',
                marketName: br.marketName ? br.marketName : 'Bookmaker',
                runners: bmRunners,
                status: br.status ? br.status : 'OPEN',
              };
            else
              bookMakerOdds.push({
                marketId: br.marketId ? br.marketId : '-1',
                marketName: br.marketName ? br.marketName : 'Bookmaker',
                runners: bmRunners,
                status: br.status ? br.status : 'OPEN',
              });
          }
        }
        bookMakerOdds.sort((a, b) => {
          const aDesc = a.marketName;
          const bDesc = b.marketName;
          if (aDesc > bDesc) return 1;
          else if (aDesc < bDesc) return -1;
          else return 0;
        });
        marketsMap[`${sportId}-${competitionId}-${eventId}`].bookmakers =
          bookMakerOdds;
        marketsMap[`${sportId}-${competitionId}-${eventId}`].enableBookmaker =
          action.payload.enableBookmaker;
        return {
          ...state,
          secondaryMultiMarketsMap: marketsMap,
        };
      }
    }

    case UPDATE_MULTIMARKET_FANCY_MARKETS: {
      const { sportId, competitionId, eventId } = action.payload;
      const fancyOddsData = action.payload.fancyUpdateData;
      const marketsMap = { ...state.secondaryMultiMarketsMap };

      // Add event data
      if (action.payload.eventId) {
        if (!marketsMap[`${sportId}-${competitionId}-${eventId}`]) {
          marketsMap[`${sportId}-${competitionId}-${eventId}`] = {
            ...marketsMap[`${sportId}-${competitionId}-${eventId}`],
            fancyMarkets: [],
            enableFancy: false,
          };
        }

        // Set Fancy markets data
        let fancyOdds: FancyMarketDTO[] = [];
        if (fancyOddsData && fancyOddsData.length > 0) {
          for (let f of fancyOddsData) {
            fancyOdds.push({
              marketId: f.marketId ? f.marketId : '',
              marketName: f.marketName ? f.marketName : '',
              status: f.status ? f.status : '',
              sort: f.sort ? Number(f.sort) : 0,
              layPrice: f.noValue
                ? f.marketType === 'fancy3' || f.marketType === 'odd-even'
                  ? f.noValue.toFixed(2)
                  : f.noValue
                : null,
              backPrice: f.yesValue
                ? f.marketType === 'fancy3' || f.marketType === 'odd-even'
                  ? f.yesValue.toFixed(2)
                  : f.yesValue
                : null,
              laySize: f.noRate ? f.noRate : null,
              backSize: f.yesRate ? f.yesRate : null,
              category: getFancyCategory(f.category),
            });
          }
        } else if (
          marketsMap[`${sportId}-${competitionId}-${eventId}`].fancyMarkets
        ) {
          fancyOdds =
            marketsMap[`${sportId}-${competitionId}-${eventId}`].fancyMarkets;
          for (let fMarket of fancyOdds) {
            fMarket.status = 'SUSPENDED';
          }
        }

        fancyOdds.sort((a, b) => {
          const aDesc = a?.marketName?.toLocaleLowerCase();
          const bDesc = b?.marketName?.toLocaleLowerCase();
          if (aDesc > bDesc) return 1;
          else if (aDesc < bDesc) return -1;
          return 0;
        });
        marketsMap[`${sportId}-${competitionId}-${eventId}`].fancyMarkets =
          fancyOdds;
        // marketsMap[eventId].enableFancy = action.payload.enableFancy;
      }
      return {
        ...state,
        secondaryMultiMarketsMap: marketsMap,
      };
    }

    default:
      return state;
  }
};

export default multiMarketReducer;
